// Watch chapters of longread
import docReady from '../../../assets/js/docReady';
import '../../shared/youtubeVideo/youtubeVideo';
import { clickableTable, scrollableTable } from '../../../assets/js/helpers/table';
import { tryResumeOrderFlow } from '../../../assets/js/helpers/orderFlow';

docReady(() => {
    document.querySelectorAll('.longread-component  .table-container').forEach((table) => {
        scrollableTable(table);
        clickableTable(table);
    });

    const navComponent = document.querySelector('.longread-with-nav-component');
    if (!navComponent) return;

    const navigationLinks = Array.from(navComponent.querySelectorAll('a'));
    const headers = Array.from(navComponent.querySelectorAll('h2'));

    function updateClass(element, className, add) {
        if (add) {
            element.classList.add(className);
        } else {
            element.classList.remove(className);
        }
    }

    function updateVisibleActive() {
        let visible = false;

        navigationLinks.forEach((navigationLink) => {
            const isCurrentFocusX = navigationLink.classList.contains('current-focus-x');
            updateClass(navigationLink, 'current-focus', isCurrentFocusX && !visible);

            if (isCurrentFocusX) visible = true;
        });
    }

    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            const headerId = `#${entry.target.id}`;
            const navLink = navigationLinks.find((link) => link.getAttribute('href') === headerId);

            updateClass(navLink, 'current-focus-x', entry.isIntersecting);

            updateVisibleActive();
        });
    }, {
        root: null,
        threshold: 0,
    });

    headers.forEach((header) => observer.observe(header));
});
tryResumeOrderFlow();
